import { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { Button, Checkbox, Col, Form, Input, InputNumber, Row, Select, Upload, UploadFile } from 'antd';
import {
  CREATE_SUCCESS_MESSAGE,
  FIELD_REQUIRED,
  IMAGE_SIZE_LESS_5MB,
  INVALID_IMAGE_FORMAT,
  NO_EMOJI,
  NO_SPACE,
  NUMBER_INTEGER,
  UPDATE_SUCCESS_MESSAGE,
} from 'src/constants/message';
import { PATH } from 'src/configs/path';
import uploadFile from 'src/apis/service/uploadFile';
import config from 'src/serviceConfig.json';
import { CreateItemDto, Item, ItemReceiveMethodEnum, Slider } from 'src/graphql/type.interface';

import {
  MAX_IMAGE_SIZE,
  multiSpaceRegex,
  RECEIVE_METHOD_DISPLAY,
  regex,
  TYPE_ITEM_DISPLAY,
} from 'src/constants/constants';
import { beforeUploadImage } from 'src/constants/function';
import { useCreateItemMutation } from 'src/graphql/mutations/createItem.graphql';
import { useUpdateItemMutation } from 'src/graphql/mutations/updateItem.graphql';
import { number, object, string } from 'yup';

type TypeProps = { currentItem?: Item };

const yupSync = {
  async validator({ field }: any, value: any) {
    await object({
      name: string()
        .required(FIELD_REQUIRED)
        .test({ test: (value) => !value?.match(multiSpaceRegex), message: NO_SPACE })
        .matches(regex, NO_EMOJI),
      description: string()
        .required(FIELD_REQUIRED)
        .test({ test: (value) => !value?.match(multiSpaceRegex), message: NO_SPACE })
        .matches(regex, NO_EMOJI),
      point: number().required(FIELD_REQUIRED).integer(NUMBER_INTEGER).min(1, 'Nhập số nguyên lớn hơn hoặc bằng 1'),
      receiveMethod: string().required(FIELD_REQUIRED),
      type: string().required(FIELD_REQUIRED),
      logo: object()
        .required(FIELD_REQUIRED)
        .test({
          test: (value: any) => {
            return value?.fileList?.length ? true : false;
          },
          message: FIELD_REQUIRED,
        })
        .test({
          test: (value: any) => (value?.file ? /.+\.(png|jpe?g|svg|gif)$/i.test(value?.file?.name) : true),
          message: INVALID_IMAGE_FORMAT,
        })
        .test({
          test: (value: any) => (value.file ? value?.file?.originFileObj?.size <= MAX_IMAGE_SIZE : true),
          message: IMAGE_SIZE_LESS_5MB,
        }),
    }).validateAt(field, { [field]: value });
  },
};

export default ({ currentItem }: TypeProps) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [arrayImage, setArrayImage] = useState<UploadFile[]>([]);
  const [urlThumbnail, setUrlThumbnail] = useState<string>('');
  const [isActive, setIsActive] = useState<boolean>(false);
  const [receiveType, setReceiveType] = useState<any>();

  useEffect(() => {
    if (currentItem) {
      const { name, isActivate, receiveMethod, type, description, logo, point } = currentItem;

      setIsActive(isActivate);
      receiveMethod && setReceiveType(receiveMethod);

      if (logo) {
        setArrayImage([
          {
            uid: '1',
            name: logo,
            url: logo,
            status: 'done',
          } as UploadFile,
        ]);
      }
      setUrlThumbnail(logo || '');

      form.setFieldsValue({
        logo: {
          fileList: [
            {
              uid: '1',
              name: logo,
              url: logo,
              status: 'done',
            },
          ],
        },
        name,
        isActivate,
        receiveMethod,
        type,
        description,
        point,
      });
    }
  }, [currentItem]);

  const [createItemMutation] = useCreateItemMutation({
    onCompleted() {
      enqueueSnackbar(CREATE_SUCCESS_MESSAGE, { variant: 'success' });
      navigate(PATH.items.list);
    },
    onError({ message }) {
      enqueueSnackbar(message, { variant: 'error' });
    },
  });

  const [updateItemMutation] = useUpdateItemMutation({
    onCompleted() {
      enqueueSnackbar(UPDATE_SUCCESS_MESSAGE, { variant: 'success' });
      navigate(PATH.items.list);
    },
    onError({ message }) {
      enqueueSnackbar(message, { variant: 'error' });
    },
  });

  const customRequestUpload = async (options: UploadRequestOption) => {
    const { file } = options;
    const formData = new FormData();

    formData.append('file', file);
    const res = await uploadFile(formData);

    if (res) {
      setUrlThumbnail(`${config.DOMAIN_CDN_IMAGE}/${res.data.path}`);
      setArrayImage([
        {
          uid: '1',
          name: res.data.path,
          url: `${config.DOMAIN_CDN_IMAGE}/${res.data.path}`,
          status: 'done',
        } as UploadFile,
      ]);
    }
  };

  const onFinish = async ({ description, name, point, receiveMethod, type }: CreateItemDto) => {
    if (currentItem) {
      updateItemMutation({
        variables: {
          input: {
            _id: currentItem?._id,
            description,
            logo: urlThumbnail,
            name,
            isActivate: isActive,
            receiveMethod,
            type,
            point: receiveMethod === ItemReceiveMethodEnum.STORE ? point : undefined,
          },
        },
      });
    } else {
      createItemMutation({
        variables: {
          input: {
            description,
            logo: urlThumbnail,
            name,
            isActivate: isActive,
            receiveMethod,
            type,
            point,
          },
        },
      });
    }
  };

  return (
    <Row>
      <Col offset={3} span={18}>
        <Form form={form} onFinish={onFinish}>
          <Form.Item
            label={
              <div>
                Ảnh vật phẩm <span style={{ color: 'red' }}>*</span>
              </div>
            }
            required={false}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="logo"
            rules={[yupSync]}
          >
            <Upload
              accept="*/*"
              listType="picture-card"
              multiple={false}
              beforeUpload={beforeUploadImage}
              fileList={arrayImage}
              customRequest={customRequestUpload}
              onRemove={() => {
                setArrayImage([]);
                setUrlThumbnail('');
              }}
            >
              <PlusOutlined rev={undefined} />
            </Upload>
          </Form.Item>

          <Form.Item
            label={
              <div>
                Loại vật phẩm <span style={{ color: 'red' }}>*</span>
              </div>
            }
            required={false}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="type"
            rules={[yupSync]}
          >
            <Select>
              {TYPE_ITEM_DISPLAY.map(({ value, key }) => (
                <Select.Option value={key}>{value}</Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={
              <div>
                Tên vật phẩm <span style={{ color: 'red' }}>*</span>
              </div>
            }
            required={false}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="name"
            rules={[yupSync]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={
              <div>
                Mô tả <span style={{ color: 'red' }}>*</span>
              </div>
            }
            required={false}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="description"
            rules={[yupSync]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={
              <div>
                Cách nhận<span style={{ color: 'red' }}>*</span>
              </div>
            }
            required={false}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="receiveMethod"
            rules={[yupSync]}
          >
            <Select onChange={(e) => setReceiveType(e)}>
              {RECEIVE_METHOD_DISPLAY.map(({ key, value }) => (
                <Select.Option key={key} value={key}>
                  {value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {receiveType === ItemReceiveMethodEnum.STORE && (
            <Form.Item
              label={
                <div>
                  Số point <span style={{ color: 'red' }}>*</span>
                </div>
              }
              required={false}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="point"
              rules={[yupSync]}
            >
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
          )}

          <Form.Item
            label={<div>Kích hoạt</div>}
            required={false}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="isActivate"
          >
            <Checkbox onClick={() => setIsActive(!isActive)} checked={isActive} />
          </Form.Item>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="primary" htmlType="submit">
              Lưu
            </Button>
          </div>
        </Form>
      </Col>
    </Row>
  );
};
