import { DisplayApplyEnum, DisplayOnEnum, PackageStatusEnum, TypeEnum } from 'src/enums/package.enum';
import {
  AdsMobileDisplay,
  CycleTypeEnum,
  DisplayOnPageEnum,
  GameMobileTypeEnum,
  ItemReceiveMethodEnum,
  ItemTypeEnum,
  LogActionEnum,
  MissionTypeEnum,
  PromotionCodeStatusEnum,
  PromotionCodeTypeEnum,
  PromotionConfigTypeEnum,
  PromotionRewardTypeEnum,
  PromotionStatusEnum,
  StateUserReceivedGiftEnum,
} from 'src/graphql/type.interface';

export const FO_NAMES = [
  {
    value: 'FO_COMMAND_HUY',
  },
  {
    value: 'FO_COMMAND_DK_AGAIN',
  },
  {
    value: 'FO_SERVICECODE',
  },
  {
    value: 'FO_RESULT_GHMK',
  },
  {
    value: 'FO_RESULT_HUY_HT',
  },
  {
    value: 'FO_RESULT_HUY',
  },
  {
    value: 'FO_RESULT_ROLLBACK',
  },
  {
    value: 'FO_COMMAND_GH',
  },
  {
    value: 'FO_PACKAGE_CODE',
  },
  {
    value: 'FO_COMMAND_DK',
  },
  {
    value: 'FO_RESULT_GH',
  },
  {
    value: 'FO_RESULT_DK_AGAIN',
  },
  {
    value: 'FO_COMMAND_GHMK',
  },
  {
    value: 'FO_COMMAND_HUY_HT',
  },
  {
    value: 'FO_COMMAND_ROLLBACK',
  },
  {
    value: 'FO_RESULT_DK',
  },
  {
    value: 'FO_RESULT_MONEY',
  },
  {
    value: 'FO_COMMAND_MONEY',
  },
  {
    value: 'FO_COMMAND_KGH',
  },
  {
    value: 'FO_RESULT_KGH',
  },
  {
    value: 'FO_COMMAND_Renew-Retry',
  },
];

export const TYPE_SERVICE = [
  {
    label: '0 - Gói cước theo kỳ',
    value: '0',
  },
  {
    label: '1 - Gói cước theo ngày',
    value: '1',
  },
  {
    label: '2 - Gói cước theo giờ',
    value: '2',
  },
  {
    label: '3 - Gói cước theo phút',
    value: '3',
  },
  {
    label: '4 - Gói cước theo giây',
    value: '4',
  },
];

export const MINUS_METHOD = [
  {
    label: ' 2 - Only Main - 1114 ',
    value: '1114',
  },
  {
    label: ' 8 - Trừ tiền bậc thang 5k - 1104 ',
    value: '1104',
  },
  {
    label: ' 10 - Trừ tiền bậc thang 7k - 1107 ',
    value: '1107',
  },
];

export const ALLOW_ISDN_STATUS = [
  {
    label: 'Bình thường',
    value: '0',
  },
  {
    label: 'Cho phép chặn 1 chiều',
    value: '1',
  },
  {
    label: 'Cho phép chặn 2 chiều',
    value: '2',
  },
  {
    label: 'Cho phép chặn 1 chiều và 2 chiều',
    value: '3',
  },
];

export const OPTIONORDER = [
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5',
    value: 5,
  },
  {
    label: '6',
    value: 6,
  },
  {
    label: '7',
    value: 7,
  },
  {
    label: '8',
    value: 8,
  },
];

export const OPTIONSACTIONS = [
  {
    value: null,
    label: 'Tất cả hành động',
  },
  {
    value: 'CREATE',
    label: 'Thêm mới',
  },
  {
    value: 'UPDATE',
    label: 'Sửa',
  },
  {
    value: 'DELETE',
    label: 'Xoá',
  },
  {
    value: 'APPROVE',
    label: 'Duyệt',
  },
];

export const OPTIONSPACKAGES = [
  {
    value: '',
    label: 'Tất cả hành động',
  },
  {
    value: 'CREATE',
    label: 'Thêm mới',
  },
  {
    value: 'UPDATE',
    label: 'Sửa',
  },
  {
    value: 'DELETE',
    label: 'Xoá',
  },
];

export const CUSTOMER_TYPE = [
  {
    value: 0,
    label: 'ALL',
  },
  {
    value: 1,
    label: 'GOOGLE',
  },
  {
    value: 2,
    label: 'FACEBOOK',
  },
  {
    value: 3,
    label: 'APPLE',
  },
  {
    value: 4,
    label: 'PHONE NUMBER',
  },
];

interface Action {
  [key: string]: string;
}

export const CheckOutStatus = {
  SUCCESS: 0,
};

export const ACTION_CONTENT: Action = {
  [LogActionEnum.CREATE]: 'Thêm mới',
  [LogActionEnum.UPDATE]: 'Sửa',
  [LogActionEnum.DELETE]: 'Xoá',
  [LogActionEnum.APPROVE]: 'Duyệt',
};

export const TYPES = [
  { key: TypeEnum.Odd, value: 'Lượt lẻ' },
  { key: TypeEnum.Subscription, value: 'Gói subscription' },
  { key: TypeEnum.ComboData, value: 'Combo data' },
];

export const DISPLAY_OPTION = [
  { key: AdsMobileDisplay.GAME, value: 'Trò chơi' },
  { key: AdsMobileDisplay.HOME, value: 'Trang chủ' },
  { key: AdsMobileDisplay.MISSION, value: 'Nhiệm vụ' },
  { key: AdsMobileDisplay.STORE, value: 'Cửa hàng' },
  { key: AdsMobileDisplay.TOURNAMENT, value: 'Giải đấu' },
];

export const OPTIONS_SELECT_TYPE_IN_MISSIONV2 = [
  { label: 'Đăng nhập', value: MissionTypeEnum.LOGIN },
  { label: 'Chơi game', value: MissionTypeEnum.PLAY },
  { label: 'Xem video', value: MissionTypeEnum.WATCH_VIDEO },
  { label: 'Chia sẻ kết quả game', value: MissionTypeEnum.SHARE },
  { label: 'Tham gia tour miễn phí', value: MissionTypeEnum.TOUR_FREE },
  { label: 'Tham gia tour trả phí', value: MissionTypeEnum.TOUR_PREMIUM },
  { label: 'Mua gói lẻ', value: MissionTypeEnum.INDIVIDUAL_PACKAGE },
  { label: 'Mua gói subscription tuần', value: MissionTypeEnum.WEEKLY_SUB_PACKAGE },
  { label: 'Mua gói subscription tháng', value: MissionTypeEnum.MONTH_SUB_PACKAGE },
  { label: 'Tiêu hao Energy', value: MissionTypeEnum.CONSUMPTION_ENERGY },
  { label: 'Tiêu hao GPoint', value: MissionTypeEnum.CONSUMPTION_GPOINT },
  { label: 'Tặng năng lượng cho bạn bè', value: MissionTypeEnum.GIVE_ENERGY_FRIEND },
  { label: 'Mời bạn bè chơi game', value: MissionTypeEnum.INVITE_FRIEND_PLAY_GAME },
  { label: 'Mời bạn bè tham gia sự kiện', value: MissionTypeEnum.INVITE_FRIEND_TO_EVENT },
];

export const OPTIONS_SELECT_CYCLE_IN_MISSIONV2 = [
  { label: 'Ngày', value: CycleTypeEnum.DAY },
  { label: 'Tuần', value: CycleTypeEnum.WEEK },
  { label: 'Tháng', value: CycleTypeEnum.MONTH },
];

export const OPTIONS_SELECT_REWARD_IN_MISSIONV2 = [
  { label: 'GPoint', value: 'GPOINT' },
  { label: 'Energy', value: 'ENERGY' },
  { label: 'Lượt', value: 'TURN' },
];

export const DISPLAY_SCREEN = [
  { key: DisplayOnEnum.ALL, value: 'Mobile và Portal' },
  { key: DisplayOnEnum.MOBILE, value: 'Mobile' },
  { key: DisplayOnEnum.WEBSITE, value: 'Portal' },
];

export const DISPLAY_APPLY_FOR = [
  { key: DisplayApplyEnum.MOBILE, value: 'MOBILE' },
  { key: DisplayApplyEnum.WEBSITE, value: 'WEBSITE' },
];

export const DISPLAY_OUTSTANDING = [
  { key: 'All', value: 'Portal và Mobile' },
  { key: 'Portal', value: 'Portal' },
  { key: GameMobileTypeEnum.PROMINENCE, value: 'Mobile' },
];

export const DISPLAY_USER_GIFT_STATUS = [
  { key: StateUserReceivedGiftEnum.PUSHED_NOTI, value: 'Đã trúng thưởng' },
  { key: StateUserReceivedGiftEnum.REAL_RECEIVED, value: 'Đã trao' },
  { key: StateUserReceivedGiftEnum.RECEIVED_IN_GAME, value: 'Đã nhận' },
];

export const STATUS = [
  { key: PackageStatusEnum.RENEW, value: 'Gia hạn' },
  { key: PackageStatusEnum.REGISTER, value: 'Đăng kí' },
  { key: PackageStatusEnum.PENDING, value: 'Chờ' },
  { key: PackageStatusEnum.CANCEL, value: 'Huỷ' },
];

export const PROMOTION_CONFIG_TYPE = [
  { key: PromotionConfigTypeEnum.PURCHASE_PACKAGE, value: 'Mua gói cước' },
  { key: PromotionConfigTypeEnum.DO_MISSION, value: 'Làm nhiệm vụ' },
];

export const PROMOTION_DISPLAY_STATUS = [
  { key: PromotionStatusEnum.IN_COMING, value: 'Sắp diễn ra' },
  { key: PromotionStatusEnum.HAPPENING, value: 'Đang diễn ra' },
  { key: PromotionStatusEnum.FINISHED, value: 'Đã kết thúc' },
];

export const PROMOTION_CODE_DISPLAY_STATUS = [
  { key: PromotionCodeStatusEnum.NOT_WIN_PRIZE, value: 'Không trúng giải' },
  { key: PromotionCodeStatusEnum.PENDING, value: 'Đang chờ' },
  { key: PromotionCodeStatusEnum.WIN_PRIZE, value: 'Trúng giải' },
  { key: PromotionCodeStatusEnum.EXPIRED, value: 'Hết hạn' },
];

export const PROMOTION_CODE_DISPLAY_TYPE = [
  { key: PromotionCodeTypeEnum.SPECIAL, value: 'Mã đặc biệt' },
  { key: PromotionCodeTypeEnum.TEMPORARILY, value: 'Mã đợt' },
];

export const PROMOTION_REWARD_DISPLAY_TYPE = [
  { key: PromotionRewardTypeEnum.SPECIAL, value: 'Giải đặc biệt' },
  { key: PromotionRewardTypeEnum.FIRST, value: 'Giải nhất' },
  { key: PromotionRewardTypeEnum.SECOND, value: 'Giải nhì' },
  { key: PromotionRewardTypeEnum.THIRD, value: 'Giải ba' },
  { key: PromotionRewardTypeEnum.CONSOLATION, value: 'Giải khuyến khích' },
];

export const NEWS_DISPLAY_ON = [{ key: DisplayOnPageEnum.MTOUR, value: 'MTOUR' }];

export const RECEIVE_METHOD_DISPLAY = [
  { key: ItemReceiveMethodEnum.ARCHIVEMENT, value: 'Đạt thành tựu' },
  { key: ItemReceiveMethodEnum.STORE, value: 'Mua từ của hàng' },
];

export const TYPE_ITEM_DISPLAY = [
  { key: ItemTypeEnum.AVATAR_BOUNDARY, value: 'Khung ảnh đại diện' },
  { key: ItemTypeEnum.BACKGROUND, value: 'Ảnh bìa' },
  { key: ItemTypeEnum.CHAT_BUBBLE, value: 'Bong bóng chat' },
];

export const DISPLAY_GENDERS = [
  {
    value: 1,
    label: 'Nam',
  },
  {
    value: 2,
    label: 'Nữ',
  },
  {
    value: 3,
    label: 'Khác',
  },
];

export const PACKAGE_ACTION = [
  { key: 'CREATE', value: 'Thêm' },
  { key: 'UPDATE', value: 'Sửa' },
  { key: 'DELETE', value: 'Xóa' },
];

export const FEEDBACK_TYPES = [
  { label: 'Công khai', value: 'PUBLIC' },
  { label: 'Ẩn danh', value: 'INCOGNITO' },
];

export const MAX_IMAGE_SIZE = 5 * 1024 * 1024;

export const REGEX_NO_SPECIAL_CHARACTER =
  /^[^a-zA-Z0-9\sáàảãạăắằẳẵặâấầẩẫậéèẻẽẹêếềểễệíìỉĩịóòỏõọôốồổỗộơớờởỡợúùủũụưứừửữựýỳỷỹỵÁÀẢÃẠĂẮẰẲẴẶÂẤẦẨẪẬÉÈẺẼẸÊẾỀỂỄỆÍÌỈĨỊÓÒỎÕỌÔỐỒỔỖỘƠỚỜỞỠỢÚÙỦŨỤƯỨỪỬỮỰÝỲỶỸỴ]+$/u;

export const regex =
  /^[a-zA-Z0-9ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựýỳỵỷỹ\s(+)|/?;*['"“”.!:_.,-~`@#$%^&*={}<>$]+$/u;

export const REGEX_NO_SPECIAL_CHARACTER_NO_EMOJI =
  /^[a-zA-Z0-9ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựýỳỵỷỹ\s]+$/u;

export const multiSpaceRegex = /(?=^[ ])|(?=[ ] $) |(?=[ ]{2,})/g;
