import type * as Types from '../type.interface';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateItemMutationVariables = Types.Exact<{
  input: Types.CreateItemDto;
}>;

export type CreateItemMutationResponse = { __typename?: 'Mutation' } & {
  createItem: { __typename?: 'Item' } & Pick<
    Types.Item,
    | '_id'
    | 'createdAt'
    | 'createdBy'
    | 'description'
    | 'isActivate'
    | 'isDeleted'
    | 'logo'
    | 'name'
    | 'point'
    | 'receiveMethod'
    | 'type'
    | 'updatedAt'
    | 'updatedBy'
  >;
};

export const CreateItemDocument = gql`
  mutation createItem($input: CreateItemDto!) {
    createItem(input: $input) {
      _id
      createdAt
      createdBy
      description
      isActivate
      isDeleted
      logo
      name
      point
      receiveMethod
      type
      updatedAt
      updatedBy
    }
  }
`;
export function useCreateItemMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateItemMutationResponse, CreateItemMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateItemMutationResponse, CreateItemMutationVariables>(CreateItemDocument, options);
}
export type CreateItemMutationHookResult = ReturnType<typeof useCreateItemMutation>;
export type CreateItemMutationResult = Apollo.MutationResult<CreateItemMutationResponse>;
export type CreateItemMutationOptions = Apollo.BaseMutationOptions<
  CreateItemMutationResponse,
  CreateItemMutationVariables
>;
