export const FIELD_REQUIRED = 'Trường bắt buộc';
export const MIN_8_CHARACTER = 'Tối thiểu 8 ký tự';
export const MIN_5_CHARACTER = 'Tối thiểu 5 ký tự';
export const MAX_300_CHARACTER = 'Tối đa 300 ký tự';
export const MAX_25_CHARACTER = 'Tối đa 25 ký tự';
export const MAX_30_CHARACTER = 'Tối đa 30 ký tự';
export const MAX_200_CHARACTER = 'Tối đa 200 ký tự';
export const MAX_255_CHARACTER = 'Tối đa 255 ký tự';
export const MAX_500_CHARACTER = 'Tối đa 500 ký tự';
export const MAX_1000_CHARACTER = 'Tối đa 1000 ký tự';
export const NO_SPECIAL_CHARACTER_NO_EMOJI = 'Không có ký tự đặc biệt và không có emoji';
export const NO_SPECIAL_CHARACTER = 'Không có ký tự đặc biệt';
export const NO_EMOJI = 'Không có emoji';
export const UPLOAD_FAILURE_MESSAGE = 'Lỗi tải lên';
export const URL_YOUTUBE = 'Đường dẫn youtube không đúng';
export const URL_YOUTUBE_AND_INTERNAL_LINK = 'Đường dẫn không đúng';
export const NUMBER_INTEGER = 'Nhập số nguyên';
export const NO_SPACE = 'Không có nhiều khoảng trắng';
export const IMAGE_SIZE_LESS_5MB = 'Ảnh không thể vượt quá 5MB';
export const INVALID_IMAGE_FORMAT = 'Định dạng ảnh không hợp lệ';
export const INVALID_EXCEL_FORMAT = 'Định dạng file không hợp lệ';
export const NO_SPECIAL_CHARACTER_MINUS_DASH = 'Không có khoảng trắng và ký tự đặc biệt';

//AUTH MESSAGE
export const LOGIN_SUCCESS_MESSAGE = 'Đăng nhập thành công';
export const LOGIN_FAILURE_MESSAGE = 'Đăng nhập không thành công!';
export const LOGOUT_SUCCESS_MESSAGE = 'Đã đăng xuất!';
export const REGISTER_SUCCESS_MESSAGE = 'Đăng ký tài khoản thành công. Vui lòng đăng nhập!';
export const RESET_PASSWORD_MESSAGE = 'Vui lòng đổi mật khẩu sau lần đầu tiên đăng nhập';
export const CHANGE_PASSWORD_SUCCESS_MESSAGE = 'Đổi mật khẩu thành công';
export const TOKEN_EXPIRED_MESSAGE = 'Phiên làm việc đã hết hạn. Vui lòng đăng nhập lại';

//API MESSAGE
export const GET_DETAIL_ERROR_MESSAGE = 'Không thể lấy thông tin chi tiết!';
export const GET_LIST_ERROR_MESSAGE = 'Không thể lấy thông tin danh sách!';
export const EXPORT_EXCEL_ERROR = 'Tải xuống file excel lỗi';
export const DELETE_ERROR_MESSAGE = 'Không thể xóa!';
export const DELETE_SUCCESS_MESSAGE = 'Xóa thành công!';
export const CREATE_SUCCESS_MESSAGE = 'Thêm thành công!';
export const CREATE_ERROR_MESSAGE = 'Thêm thất bại!';
export const UPDATE_SUCCESS_MESSAGE = 'Cập nhật thông tin thành công!';
export const UPDATE_ERROR_MESSAGE = 'Cập nhật thông tin thất bại!';
export const APPROVE_SUCCESS_MESSAGE = 'Duyệt thành công!';
export const APPROVE_ERROR_MESSAGE = 'Duyệt thất bại!';
export const CLONE_SUCCESS_MESSAGE = 'Sao chép thông tin gói cước thành công';

//VALIDATE FORM
export const INVALID_SEQUENCE_NUMBER_MESSAGE = 'Số thứ tự không hợp lệ';

export const NO_SPACES_MESSAGE = 'Không được chứa khoảng trắng trong tên người dùng';
export const USERNAME_FORM_MESSAGE =
  'Tài khoản không dấu, không khoảng trắng, không kí tự đặc biệt, không dấu chấm phẩy, không emoji tối thiểu 5 kí tự, tối đa 25 kí tự!';
export const REALNAME_FORM_MESSAGE =
  'Chỉ nhập các chữ cái, không ký tự đặc biệt, số, dấu chấm phẩy, không có 2 khoảng trắng liền nhau và độ dài từ 6 đến 50 ký tự';
export const EMAIL_FORM_MESSAGE = 'Đia chỉ email không hợp lệ';
export const PASSWORD_FORM_MESSAGE =
  'Mật khẩu có ít nhất một chữ hoa, một chữ thường, một chữ số và một ký tự đặc biệt, không khoảng trắng, không emoji và độ dài từ 8 đến 40 ký tự';
export const PASSWORD_MESSAGE_TOO_LONG = 'Mật khẩu không vượt quá 40 kí tự';
export const PASSWORD_MESSAGE_TOO_SHORT = 'Mật khẩu nhiều hơn 8 kí tự';
export const CONFIRM_PASSWORD_FORM_MESSAGE = 'Xác nhận mật khẩu không khớp';
export const UPLOAD_IMAGE_MESSAGE = 'Vui lòng tải lên hình ảnh ';

export const ROLE_REQUIRED_MESSAGE = 'Quyền cho nhóm là bắt buộc!';
export const READ_PERMISSION_REQUIRED_MESSAGE = 'Quyền xem là bắt buộc!';

export const NUMBER_DIVISIBLE_10 = 'Nhập số chia hết cho 10';
export const NUMBER_GREATER_THAN_OR_EQUAL_TO_0 = 'Số phải lớn hơn hoặc bằng 0';
export const NUMBER_GREATER_THAN_OR_EQUAL_TO_1 = 'Số phải lớn hơn hoặc bằng 1';
export const NUMBER_LESS_THAN_OR_EQUAL_TO_100 = 'Số phải nhỏ hơn hoặc bằng 100';

export const VIDEO_TYPE_VALIDATION = 'Chỉ chấp nhận video/mp4 file';
export const IMAGE_TYPE_VALIDATION = 'Vui lòng upload image tại đây';
export const NO_MULTIPLE_UPLOAD = 'Upload nhiều file không được hỗ trợ';
export const MAX_5 = 'Số nhỏ hơn hoặc bằng 5';
