import { Package } from 'src/types/package';
import { RestResponse } from 'src/types/api';
import { callApi } from 'src/apis/connection/callApi';
import { PaymentBillingChannelEnum } from 'src/enums/payment.enum';
import { Payment } from 'src/types/payment';
import { BasePaginationMeta } from 'src/types/publisher';

export type ReportPaymentQuery = {
  limit: number;
  page: number;
  keyword?: string;
  startTime?: string;
  endTime?: string;
  packageCode?: string;
  billingChannel?: PaymentBillingChannelEnum;
};

type ReportPaymentResponse = {
  data: { items: Payment[]; meta: BasePaginationMeta };
} & RestResponse;

type ReportPaymentByTransactionIdResponse = { data: Payment } & RestResponse;

export default {
  getReportPayment(query: ReportPaymentQuery): Promise<ReportPaymentResponse> {
    return callApi({ method: 'get', uri: '/payment/v1/report', query });
  },

  getReportPaymentByTransactionId(transactionId: string): Promise<ReportPaymentByTransactionIdResponse> {
    return callApi({ method: 'get', uri: `/payment/v1/report/${transactionId}` });
  },
};
